import React, { useState, useEffect } from 'react';
import Header from '../components/layouts/Header';
import Blog from '../components/elements/Blog';
import Pagination from '../components/elements/Pagination';

const allBlogs = [
    {
        id: 1,
        title: '5 Best App Development Tool for Your Project',
        image: 'images/blog/1.svg',
        filesource: '../../blogs/best-app-development-tool.md',
        date: '09 February, 2020',
        author: 'Boyan',
        category: 'Reviews',
    },
    {
        id: 2,
        title: 'Common Misconceptions About Payment',
        image: 'images/blog/2.svg',
        filesource: '../../blogs/common-misconceptions-about-payment.md',
        date: '07 February, 2020',
        author: 'Boyan',
        category: 'Tutorial',
    },
    {
        id: 3,
        title: '3 Things To Know About Startup Business',
        image: 'images/blog/3.svg',
        filesource: '../../blogs/things-to-know-about-startup-business.md',
        date: '06 February, 2020',
        author: 'Boyan',
        category: 'Business',
    },
    {
        id: 4,
        title: '5 Best App Development Tool for Your Project',
        image: 'images/blog/1.svg',
        filesource: '../../blogs/best-app-development-tool.md',
        date: '09 February, 2020',
        author: 'Boyan',
        category: 'Reviews',
    },
    {
        id: 5,
        title: 'Common Misconceptions About Payment',
        image: 'images/blog/2.svg',
        filesource: '../../blogs/common-misconceptions-about-payment.md',
        date: '07 February, 2020',
        author: 'Boyan',
        category: 'Tutorial',
    },
    {
        id: 6,
        title: '3 Things To Know About Startup Business',
        image: 'images/blog/3.svg',
        filesource: '../../blogs/things-to-know-about-startup-business.md',
        date: '06 February, 2020',
        author: 'Boyan',
        category: 'Business',
    },
    {
        id: 7,
        title: '5 Best App Development Tool for Your Project',
        image: 'images/blog/1.svg',
        filesource: '../../blogs/best-app-development-tool.md',
        date: '09 February, 2020',
        author: 'Boyan',
        category: 'Reviews',
    },
    {
        id: 8,
        title: 'Common Misconceptions About Payment',
        image: 'images/blog/2.svg',
        filesource: '../../blogs/common-misconceptions-about-payment.md',
        date: '07 February, 2020',
        author: 'Boyan',
        category: 'Tutorial',
    },
    {
        id: 9,
        title: '3 Things To Know About Startup Business',
        image: 'images/blog/3.svg',
        filesource: '../../blogs/things-to-know-about-startup-business.md',
        date: '06 February, 2020',
        author: 'Boyan',
        category: 'Business',
    },
    {
        id: 10,
        title: '5 Best App Development Tool for Your Project',
        image: 'images/blog/1.svg',
        filesource: '../../blogs/best-app-development-tool.md',
        date: '09 February, 2020',
        author: 'Boyan',
        category: 'Reviews',
    },
];

function Bloglist() {
    // document.body.classList.add("dark");
    //Uncomment the above line if you use dark version

    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);

    useEffect(() => {
        setPosts(allBlogs);
    }, []);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };

    const [toggleMenu, setToggleMenu] = useState(false);

    const headerToggler = (e) => {
        e.preventDefault();
        setToggleMenu(!toggleMenu);
    };

    document.addEventListener('click', function (e) {
        if (e.target.closest('.content')) {
            setToggleMenu(false);
        }
    });

    return (
        <>
            <Header
                logoSource="/images/logo.svg"
                toggleMenu={toggleMenu}
                headerToggler={headerToggler}
            />
            <main className={toggleMenu ? 'content open' : 'content'}>
                <div className="spacer" data-height="96"></div>
                <div className="blog-page-section">
                    <div className="container">
                        <div className="row blog-wrapper fix-spacing">
                            {currentPosts.map((blogItem) => (
                                <div className="col-md-6" key={blogItem.id}>
                                    <Blog blogData={blogItem} />
                                </div>
                            ))}
                        </div>
                        <div className="spacer" data-height="50"></div>
                        {!(posts.length > postsPerPage) ? null : (
                            <Pagination
                                itemsPerPage={postsPerPage}
                                totalItems={posts.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}
                    </div>
                </div>
                <div className="spacer" data-height="96"></div>
            </main>
        </>
    );
}

export default Bloglist;
