import React, { useState, useEffect } from 'react';
import FmItem from '../components/elements/FrontendMentorItem';
import Pagination from '../components/elements/Pagination';
import Logo from '../components/elements/Logo';
const allBlogs = [
    {
        title: 'Loopstudios landing page',
        image: 'images/portfolio/loopstudio.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/loopstudios-landing-page',
        liveUrl: 'https://loop-studios-landing-page-react.vercel.app/',
        desc: 'React Styled Component',
    },
    {
        title: 'Pod request access landing page',
        image: 'images/portfolio/pod-request.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/pod-request-access-landing-page',
        liveUrl: 'https://pod-request-access-landing-page-one.vercel.app/',
        desc: 'HTML CSS JS',
    },
    {
        title: 'Profile card component',
        image: 'images/portfolio/profile-card.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/profile-card-component-main',
        liveUrl: 'https://profile-card-component-solution-git-master.bliu1236.vercel.app/',
        desc: 'HTML CSS Flexbox',
    },
    {
        title: 'Testimonials grid section',
        image: 'images/portfolio/testimonials-grid-section.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/testimonials-grid-section-main',
        liveUrl: 'https://testimonials-grid-section-solution.vercel.app/',
        desc: 'HTML CSS Grid',
    },
    {
        title: 'Designo multi-page website',
        image: 'images/portfolio/designo.png',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/designo-multi-page-website',
        liveUrl: 'https://designo-multi-page-website-navy.vercel.app/',
        desc: 'React Styled Component React Router',
    },
    {
        title: 'FAQ accordion card',
        image: 'images/portfolio/faq-card.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/faq-accordion-card-main',
        liveUrl: 'https://faq-accordion-card-solution-six.vercel.app/',
        desc: 'HTML CSS Flexbox',
    },
    {
        title: 'Arch Studio multi-page website',
        image: 'images/portfolio/arch.png',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/arch-studio-multi-page-website',
        liveUrl: 'https://arch-studio-multi-page-website.bliu1236.vercel.app/',
        desc: 'React SASS React Router',
    },
    {
        title: 'Article preview component',
        image: 'images/portfolio/article-preview.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/article-preview-component-master',
        liveUrl: 'https://article-preview-component-solution-blue.vercel.app/',
        desc: 'HTML CSS Flexbox',
    },
    {
        title: 'Coding bootcamp testimonials slider',
        image: 'images/portfolio/coding-bootcamp.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/coding-bootcamp-testimonials-slider-master',
        liveUrl: 'https://coding-bootcamp-testimonials-slider-solution.vercel.app/',
        desc: 'HTML CSS Flexbox',
    },
    {
        title: 'Social media dashboard with theme switcher',
        image: 'images/portfolio/social-media-dashboard.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/social-media-dashboard-with-theme-switcher',
        liveUrl: 'https://social-media-dashboard-with-theme-switch-solution.vercel.app/',
        desc: 'React Styled Component',
    },    {
        title: 'Minimalist portfolio website',
        image: 'images/portfolio/minimalist.png',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/minimalist-portfolio-website',
        liveUrl: 'https://minimalist-portfolio-website-react.vercel.app/',
        desc: 'React Styled Component',
    },
    {
        title: 'Pricing component with toggle',
        image: 'images/portfolio/pricing-component.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/pricing-component-with-toggle',
        liveUrl: 'https://pricing-component-with-toggle-sepia-nu.vercel.app/',
        desc: 'React SCSS React Router Dom',
    },
    {
        title: 'Four card feature section',
        image: 'images/portfolio/four-card.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/four-card-feature-section-master',
        liveUrl: 'https://four-card-feature-section-solution-tau.vercel.app/',
        desc: 'HTML CSS Flexbox',
    },
        {
        title: 'Base Apparel coming soon page',
        image: 'images/portfolio/four-card.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/base-apparel-coming-soon-master',
        liveUrl: 'https://base-apparel-coming-soon-solution.vercel.app/',
        desc: 'HTML CSS Flexbox',
    },
         {
        title: 'Intro component with sign-up form',
        image: 'images/portfolio/intro-component.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/intro-component-with-signup-form-master',
        liveUrl: 'https://intro-component-with-signup-form-solution.vercel.app/',
        desc: 'HTML CSS Flexbox',
    },
       {
        title: 'Single price grid component',
        image: 'images/portfolio/single-price-grid.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/single-price-grid-component-master',
        liveUrl: 'https://single-price-grid-component-solution-gold.vercel.app/',
        desc: 'HTML CSS Flexbox',
    },
           {
        title: 'Clipboard landing page',
        image: 'images/portfolio/clipboard-landing-page.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/clipboard-landing-page',
        liveUrl: 'https://clipboard-landing-page-react-c9rlqu1x3.vercel.app/',
        desc: 'React Styled Component',
    },
     {
        title: 'Ping single column coming soon page',
        image: 'images/portfolio/ping-single-column.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/ping-coming-soon-page-master',
        liveUrl: 'https://ping-coming-soon-page-solution.vercel.app/',
        desc: 'HTML CSS JS Flexbox',
    },
         {
        title: 'Huddle landing page with a single introductory section',
        image: 'images/portfolio/huddle-landing-page.jpg',
        gitUrl: 'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/huddle-landing-page-with-single-introductory-section-master',
        liveUrl: 'https://huddle-landing-page-with-single-introductory-section-solution.vercel.app/',
        desc: 'HTML CSS JS Flexbox',
    },
];

function FrontEndMentorList() {
    document.body.classList.add("dark");

    //Uncomment the above line if you use dark version

    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);

    useEffect(() => {
        setPosts(allBlogs);
    }, []);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };

 


    return (
        <>

            <main className="fm-page">
            <div className="header-info">
                <h1 >Frontend Mentor Challenges Solutions</h1>
                <p >By Boyan Liu</p>
            </div>
            <Logo logoSource="/images/logo-b-light.svg"/>
                <div className="spacer" data-height="96"></div>
                <div className="blog-page-section">
                    <div className="fm-container">
                        <div className="row blog-wrapper fix-spacing">
                            {currentPosts.map((blogItem,id) => (
                                <div className="col-md-6 col-lg-4" key={id}>
                                    <FmItem data={blogItem} />
                                </div>
                            ))}
                        </div>
                        <div className="spacer" data-height="50"></div>
                        {!(posts.length > postsPerPage) ? null : (
                            <Pagination
                                itemsPerPage={postsPerPage}
                                totalItems={posts.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}
                    </div>
                </div>
                <div className="spacer" data-height="96"></div>
            </main>
        </>
    );
}

export default FrontEndMentorList;
