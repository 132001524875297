import React, { useState, useEffect } from 'react';
import Pagetitle from '../elements/Pagetitle';
import Portfolio from '../elements/Portfolio';

const filters = [
    {
        id: 1,
        text: 'Everything',
    },
    {
        id: 2,
        text: 'creative',
    },
    {
        id: 3,
        text: 'art',
    },
    {
        id: 4,
        text: 'design',
    },
    {
        id: 5,
        text: 'branding',
    },
];

const allData = [
    {
        id: 1,
        title: 'Arch Studio Multi Page',
        category: 'Web App',
        image: 'images/portfolio/arch.png',
        link: ['https://arch-studio-multi-page-website.bliu1236.vercel.app/'],
        githubLink:
            'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/arch-studio-multi-page-website',
        description: 'React.Js, React hooks,SASS',
    },
    {
        id: 2,
        title: 'Designo Multi Page',
        category: 'Web App',
        image: 'images/portfolio/designo.png',
        link: 'https://designo-multi-page-website-navy.vercel.app/',
        githubLink:
            'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/designo-multi-page-website',
        description: 'React.Js, React hooks,Styled Component',
    },
    {
        id: 3,
        title: 'Minimalist Portfolio Website',
        category: 'Web App',
        image: 'images/portfolio/minimalist.png',
        link: 'https://minimalist-portfolio-website-react.vercel.app/',
        githubLink:
            'https://github.com/BoyanLiuu/Frontend-Mentor-Solution/tree/master/minimalist-portfolio-website',
        description: 'React.Js, React hooks,SASS',
    },
    {
        id: 4,
        title: 'Darm Room',
        category: 'Web App',
        image: 'images/portfolio/movie.png',
        link: 'https://boyanliuu.github.io/movie-websit/',
        githubLink: 'https://github.com/BoyanLiuu/movie-websit',
        description: 'Vanilla JavaScript, Bootstrap, Jquery',
    },
    {
        id: 5,
        title: 'Arcade Chat Room',
        category: 'Web App',
        image: 'images/portfolio/chat-room.png',
        link: 'https://arcade-chatting-room.herokuapp.com/',
        githubLink: 'https://github.com/BoyanLiuu/chatting-room',
        description:
            'Vanilla JavaScript, Node.Js, Express, Mongoose, Socket.io',
    },
    {
        id: 6,
        title: 'Fictitious Clients',
        category: 'Web App',
        image: 'images/portfolio/all-frontend.png',
        link: '/frontendmentor-challenges',
        description: 'Vanilla JavaScript, React.Js, SASS, Styled Component',
    },
    {
        id: 7,
        title: 'React Hangman Game',
        category: 'Web App',
        image: 'images/portfolio/hangman.png',
        link: 'https://hangman-react-js.herokuapp.com/',
        githubLink: 'https://github.com/BoyanLiuu/hangman-game',
        description: 'React.Js, React hooks',
    },
];

function Works() {
    const [getAllItems] = useState(allData);
    const [dataVisibleCount, setDataVisibleCount] = useState(6);
    const [dataIncrement] = useState(3);
    const [activeFilter, setActiveFilter] = useState('');
    const [visibleItems, setVisibleItems] = useState([]);
    const [noMorePost, setNoMorePost] = useState(false);

    useEffect(() => {
        setActiveFilter(filters[0].text.toLowerCase());
        setVisibleItems(
            getAllItems.filter((item) => item.id <= dataVisibleCount)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoadmore = (e) => {
        e.preventDefault();
        let tempCount = dataVisibleCount + dataIncrement;
        if (dataVisibleCount > getAllItems.length) {
            setNoMorePost(true);
        } else {
            setDataVisibleCount(tempCount);
            if (activeFilter === filters[0].text.toLowerCase()) {
                console.log('they are same');
                setVisibleItems(
                    getAllItems.filter((data) => data.id <= tempCount)
                );
            } else {
                setVisibleItems(
                    getAllItems.filter(
                        (data) =>
                            data.category === activeFilter &&
                            data.id <= tempCount
                    )
                );
            }
        }
    };

    return (
        <section id="works">
            <div className="container">
                <Pagetitle title="Recent Works" />

                {/* Start Portfolio Items */}
                <div className="row portfolio-wrapper">
                    {visibleItems.map((item) => (
                        <div
                            className="col-md-6 col-sm-12 grid-item"
                            key={item.id}>
                            <Portfolio portfolio={item} />
                        </div>
                    ))}
                </div>
                {/* End Portfolio Items */}

                <div className="load-more text-center mt-4">
                    <button
                        className="btn btn-default"
                        onClick={handleLoadmore}
                        disabled={noMorePost ? 'disabled' : null}>
                        {noMorePost ? (
                            'No more items'
                        ) : (
                            <span>
                                <i className="fas fa-spinner"></i> Load more
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Works;
