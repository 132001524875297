import React from 'react';

import Pagetitle from '../elements/Pagetitle';

const aboutContent = {
    name: 'Boyan Liu',
    avatarImage: '/images/selfi.png',
    content:
        'I enjoy writing maintainable code and writing accessible HTML, using modern CSS practices and writing clean JavaScript. When writing JavaScript code, I mostly use React, but I can adapt to whatever tools are required. I always try to be up to date with the latest technologies and developments that are coming out of tech ecosystem.I’d love you to check out my work.',
};

function About() {
    return (
        <section id="about">
            <div className="container">
                <Pagetitle title="About Me" />
                <div className="row">
                    <div className="col-md-3">
                        <div className="text-center text-md-left">
                            <img
                                className="rounded"
                                style={{ width: '150px', height: '150px' }}
                                src={aboutContent.avatarImage}
                                alt={aboutContent.name}
                            />
                        </div>
                        <div
                            className="spacer d-md-none d-lg-none"
                            data-height="30"></div>
                    </div>

                    <div className="col-md-9 triangle-left-md triangle-top-sm">
                        <div className="rounded bg-white shadow-dark padding-30">
                            <div className="row">
                                <div className="col-md-12">
                                    <p>{aboutContent.content}</p>
                                    <div className="mt-3">
                                        <a
                                            href="Boyan_Liu_Resume_2021.pdf"
                                            className="btn btn-default">
                                            Download CV
                                        </a>
                                    </div>
                                    <div
                                        className="spacer d-md-none d-lg-none"
                                        data-height="30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer" data-height="70"></div>
            </div>
        </section>
    );
}

export default About;
