import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

function FrontEndMentorItem({ data }) {
  const { title,image,gitUrl,liveUrl,desc} = data;
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="blog-item rounded bg-white shadow-dark">
        <div className="thumb">
          <a href={liveUrl} target="_blank"
                                rel="noopener noreferrer">
            <img src={image} alt="blog-title" />
          </a>
        </div>
        <div className="details">
          <h4 className="my-0 title">
          <a href={liveUrl} target="_blank"
                                rel="noopener noreferrer">
              {title}
            </a>
          </h4>
          <div className="meta mb-0 mt-2 desc">
            <span >{desc}</span>
            <a
                href={gitUrl}
                target="_blank"
                rel="noopener noreferrer">
                <i className="icon-social-github gitbtn" title="Github Code Link"></i>
            </a>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default FrontEndMentorItem;
