import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { Home, BlogList, BlogDetails,FrontEndMentorList } from './pages/index';
import './App.scss';

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" component={Home} exact></Route>
                <Route path="/frontendmentor-challenges" component={FrontEndMentorList} exact />
                <Route path="/blogs" component={BlogList} exact />
                <Route
                    path="/blogs/blog-details/:id/:title"
                    component={BlogDetails}
                />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
