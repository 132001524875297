import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

function Timeline({ education, company }) {
    const { years, title, content } = education;

    const list = content.map((item, idx) => {
        return <li key={idx}>{item}</li>;
    });
    return (
        <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
            <div className="timeline-container">
                <div className="content">
                    <span className="time">{years}</span>
                    <h3 className="title">{title}</h3>
                    <h6>{company}</h6>
                    {company === '' ? <p>{content[0]} </p> : <ul>{list}</ul>}
                </div>
            </div>
        </ScrollAnimation>
    );
}

export default Timeline;
