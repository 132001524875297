import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Bachelor's Degree in Computer Science",
    years: "2018 - 2020",
    content: ["University of Maryland - College Park, College Park, MD"],
  },
  {
    id: 2,
    title: "Associate’s Degree in Computer Science",
    years: "2015 - 2017",
    content: ["Seattle Central Community College, Seattle, WA"],
  },
];

const experienceData = [
  {
    id: 1,
    title: "Web developer(Contractor)",
    years: "Mar. 2020 – Present",
    company: "Maryland Transportation Institute",
    content: [
      `Developed a prototype used to visualize transportation information using React.js, Mapbox, Context API.`,
      `Refactored React components with the help of hooks make the code cleaner and reduce the amount of code by 15%.`,
      `Increased code quality by Introducing name convention, Eslint, prettier to the team member.`,
    ],
  },
  {
    id: 2,
    title: "Software Engineer(Intern)",
    years: "Sept. 2020 – Mar, 2021",
    company: "Fraunhofer USA CESE",
    content: [
      "Developed a visual inspection system to help analyze defective parts in the washing machine for Samsung. The system developed improved the manufacturing process by 30%.",
      "Built modular and reusable components.",
      "Cooperated with team members using Git & Gitlab(Agile Development Cycle).",
      "Implemented Python Flask web framework to create RESTful API backend service.",
    ],
  },
  {
    id: 3,
    title: "Web  Developer(Intern)",
    years: "May. 2020 – Aug. 2020",
    company: "SpringGem Weather Information",
    content: [
      "Built out features of weather and road conditions forecasting web app for a professor under the University of Maryland's Department of Atmospheric and Oceanic Sciences, using Django and PostgreSQL",
      "Developed an interactive visualization map for COVID-19 pandemic, using Javascript, Bootstrap in the frontend, and Python Django, PostgreSQL for the backend.",
    ],
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} company="" />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline
                  key={experience.id}
                  education={experience}
                  company={experience.company}
                />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
